import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import { Link } from "gatsby";
// import { Link as GatsbyLink } from "gatsby";

const StyledLink = styled(Link)`
  color: white;
`;

const App = ({ img, alt, title, content, google_app_id, web_app_url }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 25 },
      }}
      transition={{ ease: "easeOut", duration: 1.25, delay: 0.35 }}
    >
      <AppWrapper className="perk">
        <img src={img} alt={alt} />
        <h3>{title}</h3>
        <p>{content}</p>
        <Link to={`https://play.google.com/store/apps/details?id=${google_app_id}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`}>
          <img alt='Google Play で手に入れよう' src='https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png'/>
        </Link>
        <StyledLink to={`${web_app_url}`}>
          <p>Webで手に入れよう!</p>
        </StyledLink>
      </AppWrapper>
    </motion.div>
  )
}

const AppWrapper = styled.article`
  padding: 1rem;
  max-width: 260px;
  margin: 0 auto;

  img {
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 992px) {
      padding: 0 1.75rem;
    }
  }

  h3 {
    font-weight: 400;
  }
`

export default App

import React from "react"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import TextBlockImg from "../components/TextBlockImg/textBlockImg"
import App from "../components/App/app"
//import Perk from "../components/Perk/perk"
//import Button from "../components/Button/button"
//import Packages from "../components/Packages/packages"
//import Package from "../components/Package/package"
//import Contact from "../components/Contact/contact"
//import { Link } from "react-scroll"

import app1Img from "../images/app/icon_digital_clock.png"
import app2Img from "../images/app/icon_kakunin_app.png"
//import app3Img from "../images/app/app3.png"

//import perk1Img from "../images/speed.svg"
//import perk2Img from "../images/piggy-bank.svg"
//import perk3Img from "../images/friendly-staff.svg"

//import { IconContext } from "react-icons"
//import { MdDone, MdClear } from "react-icons/md"

const HomePage = () => {
  return (
    <>
      <Banner />
      <TextBlock
        id="about"
        title="日常生活を楽しく快適にする"
        paragraph="「Deraii（でらいい）」サービスのご紹介"
      >
      {
      /*
        <Link to="perks" smooth={true} duration={500}>
          <Button label="Tell Me More" cta="Tell Me More!" />
        </Link>
      */
      }
      </TextBlock>

      <TextBlockImg
        id="apps"
        title="アプリ"
        subtitle="スマートフォンアプリ"
      >
        <div className="flex-container trio-block">
          <App
            img={app1Img}
            alt="クマさんのデジタル時計"
            title="クマさんのデジタル時計(β版)"
            content="クマさんと一緒に「時間」のお勉強をしよう！"
            google_app_id="com.deraii.chigaku.digital_clock"
            web_app_url="https://deraii.com/app/chigaku/digital_clock/"
          />
          <App
            img={app2Img}
            alt="端末確認君"
            title="端末確認君(β版)"
            content="端末のIPアドレスなどの端末情報をチェックすることができるアプリです。"
            google_app_id="com.deraii.kakuninapp"
            web_app_url="https://kakunin.deraii.com/"
          />
          {
          /*
          <App
            img={app3Img}
            alt="Super fast speed increases"
            title="Friendly"
            content="Advisors who are available 24/7, all with exprt knowledge"
          />
          */
          }
        </div>
      </TextBlockImg>

      {
      /*
      <Packages
        title="Our Packages"
        para="Choose the perfect solution for you. With benefits to suit all budgets Startup can offer amazing value and expert advice"
      >
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="Standard">
            <ul>
              <li>
                <MdDone />1 User
              </li>
              <li>
                <MdDone />
                1GB Storage
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Dedicated Advisor
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                24/7 Support
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Hyper" active={true}>
            <ul>
              <li>
                <MdDone />
                24/7 Support
              </li>
              <li>
                <MdDone />
                Dedicated Advisor
              </li>
              <li>
                <MdDone />
                Unlimited Storage
              </li>
              <li>
                <MdDone />
                Unlimited Users
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Super">
            <ul>
              <li>
                <MdDone />
                10 Users
              </li>
              <li>
                <MdDone />
                500GB Storage
              </li>
              <li>
                <MdDone />
                Advice Support
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Dedicated Advisor
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
        </IconContext.Provider>
      </Packages>
      */
      }
      {
      /*
      }
      <Contact
        id="contact"
        title="お問い合わせ"
        subtitle=""
      />
      */
      }
    </>
  )
}

export default HomePage
